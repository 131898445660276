import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`
export const Title = styled.h2`
  color: #fdfdfd;
  font-family: "Abril Fatface";
  font-size: 3.5rem;
  font-weight: 400;
  text-transform: uppercase;
  border: 0.2rem solid #fdfdfd;
  padding: 2rem 4rem;
  white-space: nowrap;
`
export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const LeftColumn = styled.div`
  width: 100%;
`
export const RightColumn = styled.div`
  width: 100%;
`
export const Text = styled.p`
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 1.35rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 4rem;
`
