import React from "react"
import {
  Wrapper,
  TitleWrapper,
  Title,
  ContentWrapper,
  LeftColumn,
  Text,
  RightColumn,
} from "./styles"
import AudioPlayer from "src/components/AudioPlayer"
import AudioDescription from "src/components/AudioDescription"
import VideoPlayer from "src/components/VideoPlayer"
import SmallTitle from "src/components/TitleAndParagraph"

const getAudio = attachments => {
  return attachments?.filter(attachment => attachment?.Audio)
}

const getVideo = extraFields => {
  return extraFields?.filter(field => field?.Name?.toLowerCase() === "video")
}

const TitleParagraphVideoAudio = ({
  article = {},
  style = {},
  nativecontrols = false,
  smalltitle = false,
} = {}) => {
  return (
    <Wrapper style={style}>
      {smalltitle ? (
        <SmallTitle
          article={article}
          style={{ marginBottom: 0 }}
          nolead
          nomodal
        />
      ) : (
        <TitleWrapper>
          <Title>{article.Title}</Title>
        </TitleWrapper>
      )}
      <ContentWrapper>
        <LeftColumn>
          <Text dangerouslySetInnerHTML={{ __html: article.Content }} />
          {getVideo(article.CustomFields).map((video, index) => {
            return (
              <VideoPlayer
                src={video.Value.replace(".file", ".mp4")}
                key={index}
                nativecontrols={nativecontrols}
                style={{
                  marginBottom: "3rem",
                  width: "100vw",
                  marginLeft: "-8.8%",
                }}
              />
            )
          })}
        </LeftColumn>
        <RightColumn>
          {getAudio(article?.Attachments)?.map((audio, index) => (
            <div key={audio?.UID}>
              <AudioDescription
                content={audio?.Description}
                noBreak
                key={audio?.UID}
              />
              <AudioPlayer src={audio?.Audio} />
            </div>
          ))}
        </RightColumn>
      </ContentWrapper>
    </Wrapper>
  )
}

export default TitleParagraphVideoAudio
