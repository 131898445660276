import React from "react"
import SEO from "../components/seo"
import Chapter6 from "src/views/Chapter6"
import OGImage from "../images/seo/radiowa_filharmonia.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Muzyka poważna w Polskim Radiu"
        description="Rola Polskiego Radia jako mecenasa i ostoi kultury wysokiej w polskich mediach"
        image={OGImage}
      />
      <Chapter6 />
    </>
  )
}

export default IndexPage
