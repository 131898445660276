import React from "react"
import { isMobile } from "react-device-detect"
import Desktop from "./desktop"
import Mobile from "./mobile"

const TitleParagraphVideoAudio = ({
  article = {},
  style = {},
  smalltitle = false,
  nativecontrols = false,
} = {}) => {
  if (isMobile)
    return (
      <Mobile
        article={article}
        style={style}
        smalltitle={smalltitle}
        nativecontrols={nativecontrols}
      />
    )
  return (
    <Desktop
      article={article}
      style={style}
      smalltitle={smalltitle}
      nativecontrols={nativecontrols}
    />
  )
}

export default TitleParagraphVideoAudio
